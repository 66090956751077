@import "styles/default.scss";

.text-input {
  margin: 10px 0;
  input {
    padding: 10px 15px;
    border-radius: 10px;
    border: none;
    background: $input-bg;
    font-weight: 700;
    width: auto;
    color: black;

    &.error {
      background-color: $red-bg;
    }
  }

  textarea {
    padding: 10px 15px;
    border-radius: 10px;
    border: none;
    background: $input-bg;
    font-weight: 700;
    color: black;
    resize: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.error {
      background-color: $red-bg;
    }
  }

  .error-message {
    color: $red;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    margin: 5px;
  }
}
