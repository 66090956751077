@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,900;1,400&display=swap");

#purchase {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
}

#purchase img {
  width: 100px;
  margin: 30px;
}

#purchase h1 {
  color: #652d90;

  font-weight: 900;
}

#purchase button {
  width: 200px;
  height: 60px;
  background: none;
  border-radius: 30px;
  border: 1px solid #4a9d45;
  color: #4a9d45;
}

#purchase .no-wallet {
  font-style: italic;
  color: #777;
}
