@import "styles/default.scss";

.price-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;

  input {
    padding: 10px 15px;
    border-radius: 10px;
    border: none;
    background: $input-bg;
    font-weight: 700;
    width: auto;
    color: black;

    &.error {
      background: $red-bg;
    }
  }

  .error-message {
    color: $red;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    margin: 5px;
  }
}
