@import "styles/default.scss";

.drop-item {
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;

  &.minted {
    img:hover {
      cursor: pointer;
    }
  }

  img {
    width: 190px;
    height: 160px;
    border-radius: 10px;
    object-fit: cover;
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1));
  }

  .info-container {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;

    h2 {
      font-size: 24px;
      font-weight: 700;
      margin: 0;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    h4 {
      font-size: 14px;
    }

    .alt {
      color: $placeholder;
    }

    p {
      font-weight: 500;
      overflow: hidden;
      color: $placeholder;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .button-alt {
      width: 50%;
    }

    .loader {
      margin: 5px;
      height: 25px;
      width: 25px;
      border-width: 3px;
    }
  }
}
