#gallery {
  margin: auto;
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

#gallery div {
  position: relative;
}

#gallery div img {
  position: relative;
  width: 500px;
}

#gallery div video {
  position: relative;
  width: 500px;
  display: block;
}

#gallery div:hover .image-overlay {
  display: flex;
}

#gallery div .image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

#gallery div .image-overlay button {
  width: 150px;
  background: none;
  height: 40px;
  border: solid 1px white;
  margin: auto;
  border-radius: 15px;
  color: white;
  cursor: pointer;
}

@media (max-width: 720px) {
  #gallery div {
    width: 100%;
  }
  #gallery div img {
    width: 100%;
    margin: 0;
  }

  #gallery div video {
    width: 100%;
    margin: 0;
    display: block;
  }
}
