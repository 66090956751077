@import "styles/default.scss";

.collection-card {
  position: relative;
  height: 0;
  padding-top: 160%;
  margin: 1rem 0;

  &:hover {
    cursor: pointer;
  }

  .card {
    position: absolute;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.25));
  }

  .bottom {
    top: 2rem;
    right: 2rem;
    left: 2rem;
    bottom: 2rem;
    transform: translateY(-3rem);
    background-color: $purple;
  }

  .middle {
    top: 1rem;
    right: 1rem;
    left: 1rem;
    bottom: 1rem;
    transform: translateY(-1.5rem);
    background-color: $green;
  }

  .top {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: white;

    .banner {
      flex: 1;
      max-height: 50%;
      object-fit: cover;
    }

    .info-container {
      position: relative;
      background-color: white;
      text-align: left;
      padding: 1rem;

      h3 {
        position: absolute;
        top: 0;
        right: 1rem;
        transform: translateY(-50%);
        background-color: $green;
        filter: drop-shadow(0px 1px 15px rgba(0, 0, 0, 0.1));
        color: white;
        font-weight: 700;
        font-size: 0.9rem;
        padding: 0.5rem 1.5rem;
        border-radius: 2rem;
      }

      h2 {
        margin: 0;
        font-size: 1.2rem;
        font-weight: 700;
      }

      .artist {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1rem;

        img {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
        }

        p {
          margin: 0;
          margin-left: 1rem;
          color: $placeholder;
          font-weight: 500;
        }
      }
    }

    .alt-info-container {
      background: black;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      &.ended {
        background: $placeholder;
      }

      .text-container {
        margin: 1.5rem 1rem;

        span {
          font-weight: 700;
        }
      }
    }
  }
}
