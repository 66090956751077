@import "styles/default.scss";

.datetime-input-container {
  margin: 10px 0;

  input {
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
    background: $input-bg;
    font-weight: 700;
    color: black;

    &.error {
      background-color: $red-bg;
    }
  }

  input[type="datetime-local"]:invalid::-webkit-datetime-edit {
    color: $placeholder;
  }

  .error-message {
    color: $red;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    margin: 5px;
  }
}
