@import "styles/default.scss";

.my-drops {
  margin: auto;
  width: $medium;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .header {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
      margin: 0;
    }
  }
}
