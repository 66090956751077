@import "styles/default.scss";

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: $small;
    padding: 2rem;
    background: white;
    border-radius: 10px;

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 1rem;
    }

    p {
      font-weight: 500;
      font-size: 1.1rem;
    }

    button {
      background: none;
      border: none;
      font-weight: 500;
      font-size: 1.1rem;
      color: $placeholder;
      margin: 1rem;

      &:hover {
        cursor: pointer;
      }

      &.primary {
        color: $green;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
