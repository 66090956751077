@import "styles/default.scss";

.file-input-container {
  input {
    display: none;
  }

  label {
    margin: 10px 0;
    border: 2px dashed #ccc;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 250px;
    color: $placeholder;

    &.error {
      border-color: $red;
    }

    &:hover {
      cursor: pointer;
    }

    svg {
      margin-bottom: 10px;
      fill: $placeholder;
    }

    p {
      width: 80%;
      font-weight: 500;
    }

    &.uploaded-image {
      border: none;
      width: auto;
      height: auto;

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 10px;
        filter: drop-shadow(0px 1px 15px rgba(0, 0, 0, 0.1));
      }

      svg {
        display: none;
      }

      p {
        display: none;
      }
    }
  }
  .error-message {
    color: $red;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
  }
}
